import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from '@services/api.service';
import { AwardItem } from '@interfaces/award-item.interface';

export const awardsResolver: ResolveFn<AwardItem[]> = (
	route,
	state,
	api = inject(ApiService)
) => {
	return api.getAwards();
};

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalState } from '@modal/ts/modal-state.interface';
import { ModalContainerComponent } from '@modal/components/modal-container/modal-container.component';
import { AsyncPipe } from '@angular/common';
import { ModalService } from '@modal/service/modal.service';

@Component({
	selector: 'app-smart-modal-container',
	template: ` <app-modal-container
		[modalState]="modalState$ | async"></app-modal-container>`,
	imports: [ModalContainerComponent, AsyncPipe],
	standalone: true,
})
export class SmartModalContainerComponent {
	public modalState$: Observable<ModalState>;
	constructor(private service: ModalService) {
		this.modalState$ = this.service.modalState$;
	}
}

import { Component, inject, Input } from '@angular/core';
import { AwardItem } from '@interfaces/award-item.interface';
import { ToDateStringPipe } from '@src/app/helpers/to-date-string.pipe';
import { ApiService } from '@services/api.service';
import { StatisticAction } from '@enums/statistic-action.enum';
import { firstValueFrom } from 'rxjs';

@Component({
	selector: 'app-promo-code',
	standalone: true,
	imports: [ToDateStringPipe],
	templateUrl: './promo-code.component.html',
	styleUrl: './promo-code.component.scss',
})
export class PromoCodeComponent {
	@Input() slide: AwardItem;
	@Input() set resetCopied(value: unknown) {
		console.log('PromoCodeComponent', value);
		this.isCopied = false;
	}
	private api = inject(ApiService);
	public isCopied = false;

	async copyToClipboard(value: string, isPremier?: boolean): Promise<void> {
		try {
			firstValueFrom(
				this.api.sendStatistic(
					isPremier
						? StatisticAction.COPY_PROMO_CODE_PREMIER
						: StatisticAction.COPY_PROMO_CODE_DEFAULT,
					value
				)
			);
			await navigator.clipboard.writeText(value);
			this.isCopied = true;
			console.log('Текст скопирован');
		} catch (err) {
			console.error('Ошибка при копировании: ', err);
		}
	}

	public sendStatisticMoreBtn() {
		firstValueFrom(
			this.api.sendStatistic(StatisticAction.COLLECTION_STATISTIC, 1)
		);
	}

	public sendStatisticActivateBtn() {
		firstValueFrom(
			this.api.sendStatistic(StatisticAction.CLICK_ON_ACTIVATE, 1)
		);
	}
}

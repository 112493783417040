import { Component, EventEmitter, Input, Output, Type } from '@angular/core';
import { ModalState } from '@modal/ts/modal-state.interface';
import {
	ModalComponentUnion,
	modalTypeMapper,
} from '@modal/ts/modal-type.enum';
import { NgClass, NgComponentOutlet, NgStyle } from '@angular/common';

@Component({
	selector: 'app-modal-container',
	templateUrl: './modal-container.component.html',
	styleUrl: './modal-container.component.scss',
	standalone: true,
	imports: [NgStyle, NgClass, NgComponentOutlet],
})
export class ModalContainerComponent {
	@Input() modalState: ModalState;
	@Output() clickOutside = new EventEmitter<void>();

	get activeModal(): Type<ModalComponentUnion> {
		return modalTypeMapper[this.modalState.type];
	}
}

import { Component, Input } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { StopGameResponse } from '@interfaces/stop-game-response.interface';
import { ExpressPointsPipe } from '@src/app/helpers/express-points.pipe';
import { ModalType } from '@modal/ts/modal-type.enum';
import { CrackerAnimationComponent } from '@components/cracker-animation/cracker-animation.component';

@Component({
	selector: 'app-modal-level-finish',
	templateUrl: './modal-level-finish.component.html',
	styleUrl: './modal-level-finish.component.scss',
	standalone: true,
	imports: [ExpressPointsPipe, CrackerAnimationComponent],
})
export class ModalLevelFinishComponent extends ModalBaseComponent {
	@Input() data: StopGameResponse;

	closeFinishModal() {
		if (this.data.is20LevelComplete) {
			this.openModal(ModalType.ALL_LEVELS, true);
		} else {
			this.closeAndNavigate('/core');
		}
	}
}

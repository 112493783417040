import { Component } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';

@Component({
	selector: 'app-modal-close-game',
	templateUrl: './modal-close-game.component.html',
	styleUrl: './modal-close-game.component.scss',
	standalone: true,
})
export class ModalCloseGameComponent extends ModalBaseComponent {}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalState } from '@modal/ts/modal-state.interface';
import { ModalType } from '@modal/ts/modal-type.enum';
import { ModalData } from '@modal/ts/modal-data.type';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private state$ = new BehaviorSubject<ModalState>({
		type: null,
		isOpen: false,
		animation: null,
		withOverlay: true,
	});
	private closeAnimationSpeed = 300;
	public modalState$: Observable<ModalState> = this.state$.asObservable();

	public get state(): ModalState {
		return this.state$.value;
	}

	public openModal(
		type: ModalType,
		withOverlay: boolean = true,
		data?: ModalData
	) {
		this.state$.next({
			isOpen: true,
			type,
			animation: 'open',
			data,
			withOverlay,
		});
	}

	public closeModal() {
		const state = this.state$.value;
		this.state$.next({ ...state, animation: 'close' });
		setTimeout(
			() =>
				this.state$.next({
					isOpen: false,
					type: null,
					animation: 'close',
					data: null,
					withOverlay: true,
				}),
			this.closeAnimationSpeed
		);
	}
}

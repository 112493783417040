import { Component, Input, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../service/modal.service';
import { ModalShowAnimationType } from '@modal/ts/modal-show-animation.type';
import { ModalType } from '@modal/ts/modal-type.enum';
import { ModalData } from '@modal/ts/modal-data.type';

@Component({
	selector: 'app-modal-base',
	template: '',
	standalone: true,
})
export class ModalBaseComponent {
	@Input() animation: ModalShowAnimationType;
	animationEnds$ = signal<boolean>(false);
	constructor(
		protected service: ModalService,
		protected router: Router,
		protected actRouter: ActivatedRoute
	) {
		requestAnimationFrame(() => {
			setTimeout(() => {
				this.animationEnds$.set(true);
			});
		});
	}

	closeModal() {
		this.service.closeModal();
	}

	openModal(type: ModalType, withOverlay: boolean, data?: ModalData) {
		this.service.openModal(type, withOverlay, data);
	}

	// sendStatisticsByGameExit(data: ModalExitGame) {
	// 	const action = data.paidAttempt
	// 		? StatisticAction.AMOUNT_EXITS_BY_CROSS_PAYED
	// 		: StatisticAction.AMOUNT_EXITS_BY_CROSS_FREE;
	// 	firstValueFrom(
	// 		this.server.sendStatistic(`${action}${data.gameId}`, '1')
	// 	).then(() => {
	// 		this.closeAndNavigate('/main');
	// 		this.gameService.stopGame();
	// 	});
	// }

	async closeAndNavigate(link: string) {
		console.log(link, this.router.url);
		await this.router.navigate([link], { relativeTo: this.actRouter });
		setTimeout(() => this.closeModal(), 200);
	}
}

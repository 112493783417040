<div class="faq">
  <div class="faq__inner">
    <details>
      <summary>Какой мой внутриигровой ID?</summary>
      <p>Твой внутриигровой ID - {{userId}}.</p>
    </details>
    @for(item of items; track item.question){
    <details>
      <summary>{{ item.question }}</summary>
      <p [innerHTML]="item.answer"></p>
    </details>
    }
  </div>
  <a class="faq__btn btn btn-primary" (click)="toOnboarding()">Пройти обучение</a>
</div>

<div class="collection__slider">
  <swiper-container init="false" appSwiper [extendConfig]="sliderConfig" >
    @for (slide of slides; let i= $index ;track slide) {
      <swiper-slide>
          @if (slide.level < level){
            <img class="collection__item"
              [ngSrc]="slide.image"
              height="85"
              width="78"
            />
          } @else {
            <div class="collection__slide">
                <img class="collection__percent"
                  ngSrc="./assets/images/percent.svg"
                  height="41"
                  width="41"
                />
            </div>
          }
      </swiper-slide>
    }
  </swiper-container>
  <p class="collection__text">Собери все 30 товаров по ударной цене и  получи 222 Экспресс-балла!</p>
  <div class="collection__page">{{ openedCollection }}/30</div>
</div>

<a href="auchan://category?code=den_rozhdeniya_ashan_2022" class="collection__link">Перейти в коллекцию товаров</a>

<div class="slider__btn-wrapper">
  <button class="slider__btn slider__prev" id="slide-prev"
  ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.01183 2.06532C9.74601 1.33977 10.9273 1.33984 11.6613 2.06548C12.4075 2.80308 12.4076 4.00808 11.6615 4.7458L6.2194 10.1269L21.5946 10.1269C22.6297 10.1269 23.4688 10.966 23.4688 12.0011C23.4688 13.0362 22.6297 13.8753 21.5946 13.8753L6.2194 13.8753L11.6613 19.2546C12.4075 19.9922 12.4075 21.1971 11.6613 21.9347C10.9273 22.6603 9.74606 22.6603 9.01201 21.9347L1.64924 14.6567C0.959603 13.977 0.531409 13.0389 0.531409 11.9991C0.531409 10.9613 0.959603 10.0211 1.64924 9.34145L9.01183 2.06532Z" fill=""/>
  </svg>
  </button>
  <button class="slider__btn slider__next" id="slide-next"
  ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9882 2.06532C14.254 1.33977 13.0727 1.33984 12.3387 2.06548C11.5925 2.80308 11.5924 4.00808 12.3385 4.7458L17.7806 10.1269L2.40543 10.1269C1.37035 10.1269 0.531249 10.966 0.53125 12.0011C0.53125 13.0362 1.37035 13.8753 2.40543 13.8753L17.7806 13.8753L12.3387 19.2546C11.5925 19.9922 11.5925 21.1971 12.3387 21.9347C13.0727 22.6603 14.2539 22.6603 14.988 21.9347L22.3508 14.6567C23.0404 13.977 23.4686 13.0389 23.4686 11.9991C23.4686 10.9613 23.0404 10.0211 22.3508 9.34145L14.9882 2.06532Z" fill=""/>
  </svg>
  </button>
</div>

import { Injectable } from '@angular/core';
import { Assets, Container, SCALE_MODES, Texture } from 'pixi.js';

@Injectable({
	providedIn: 'root',
})
export class ResourceLoaderService {
	private aliases: string[] = [];
	private _stage: Container;

	public loadResources(
		itemIds: number[],
		levelId: number
	): Promise<Record<string, Texture>> {
		Assets.init({
			basePath: 'assets/game-resources/',
		});
		// this.loadBackgroundAnimation(levelId);
		this.loadPlayerResources();
		this.loadPercentageLabel();
		this.loadExpressPoints();
		this.loadPlayItem(itemIds);
		return Assets.load(this.aliases);
	}

	private loadPlayerResources(): void {
		for (let i = 1; i <= 5; i++) {
			const alias = `player_grade_${i}`;
			Assets.add({
				alias,
				src: `player/${alias}.png`,
				data: { scaleMode: 'linear' },
				format: 'png',
				loadParser: 'loadTextures',
			});
			this.aliases.push(alias);
		}
	}

	private playerImageCycle(type: 'empty' | 'half' | 'full'): void {
		for (let i = 1; i <= 5; i++) {
			const alias = `player_${type}_${i}`;
			Assets.add({
				alias,
				src: `player/${alias}.svg`,
				data: { scaleMode: 'linear' },
				format: 'svg',
				loadParser: 'loadTextures',
			});
			this.aliases.push(alias);
		}
	}

	private loadPercentageLabel(): void {
		const alias = 'percentage-label';
		Assets.add({
			alias,
			src: `${alias}.svg`,
			data: { scaleMode: 'linear' },
			format: 'svg',
		});
		this.aliases.push(alias);
	}

	private loadExpressPoints(): void {
		const alias = 'item-caught';
		Assets.add({
			alias,
			src: `${alias}.png`,
			data: { scaleMode: 'linear' },
			format: 'png',
		});
		this.aliases.push(alias);
	}

	private loadPlayItem(itemIds: number[]): void {
		const items = [...itemIds];
		items.forEach(id => {
			const alias = `id${id}`;
			Assets.add({
				alias,
				src: `items/${alias}.svg`,
				data: { scaleMode: 'linear' },
				format: 'svg',
			});
			this.aliases.push(alias);
		});

		// const alias = 'test-item';
		// Assets.add({
		// 	alias,
		// 	src: `items/${alias}.svg`,
		// 	data: { scaleMode: 'linear' },
		// 	format: 'svg',
		// });
		// this.aliases.push(alias);
	}

	private loadBackgroundAnimation(level: number = 1): void {
		const correctId = ((level - 1) % 3) + 1;
		console.log('correctId for video', correctId, level);
		const alias = 'background-animation';
		Assets.add({
			alias,
			src: `https://runtest.igm.gg/static-content/video/${correctId}_lvl.webm`,
			// src: `level_${level}.mp4`,
			data: {
				preload: true,
				autoPlay: true,
			},
		});
		this.aliases.push(alias);
	}

	private loadGameItems(): void {}
}

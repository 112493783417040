import { Component } from '@angular/core';
import { ModalBaseComponent } from '@modal/components/modal-base/modal-base.component';

@Component({
	selector: 'app-modal-level-failed',
	standalone: true,
	imports: [],
	templateUrl: './modal-level-failed.component.html',
	styleUrl: './modal-level-failed.component.scss',
})
export class ModalLevelFailedComponent extends ModalBaseComponent {}

import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { PromoCodeSlide } from '@interfaces/promo-code-slide.interface';
import { SwiperDirective } from '@src/app/directives/swiper.directive';
import { AwardItem } from '@interfaces/award-item.interface';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { PromoCodeComponent } from '@components/promo-code/promo-code.component';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-promo-codes-slider',
	standalone: true,
	imports: [SwiperDirective, PromoCodeComponent, AsyncPipe],
	templateUrl: './promo-codes-slider.component.html',
	styleUrl: './promo-codes-slider.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PromoCodesSliderComponent {
	@Input() slides: AwardItem[];
	public resetCopied$ = new Subject<void>();

	reset() {}
}

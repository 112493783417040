<div class="modal-pause">
	<div class="modal-pause__inner">
    <img class="modal-pause__bird" src="./assets/images/bird.svg"/>
		<div class="modal-pause__subtitle">Пауза</div>
		<div class="modal-pause__title">Выйти из игры?</div>
		<div class="modal-component__btn-wrapper">
			<button	class="btn btn-secondary btn-yes"
				(click)="closeGame()">Да</button>
			<button	class="btn btn-primary btn-no"
				(click)="resume()">Нет, вернуться</button>
		</div>
	</div>
</div>

<swiper-container init="false" appSwiper (slideIndex)="resetCopied$.next()">
	@for (slide of slides; track slide.name) {
		<swiper-slide>
      <app-promo-code [slide]="slide" [resetCopied]="resetCopied$| async"></app-promo-code>

			<!--  Пустой блок когда нету ни купонов ни промокодов. Как и просил, сделал для него контейнер ниже! Пока закомментировал/скрыл чтобы не мешал! -->
			<!--    <div class="awards__empty">-->
			<!--      <div class="awards__empty-body">-->
			<!--        <div class="awards__empty-title">Промокод еще <br> не получен</div>-->
			<!--        <div class="awards__empty-text">Играй каждый день <br> и проходи уровни!</div>-->
			<!--      </div>-->
			<!--      <a href="#" class="awards__empty-link">Узнать больше</a>-->
			<!--    </div>-->
		</swiper-slide>
	}
</swiper-container>

import { Component } from '@angular/core';
import {
	ActivatedRoute,
	RouterLink,
	RouterLinkActive,
	RouterOutlet,
} from '@angular/router';
import { routerAnimation, slideRouter } from '@src/app/helpers/animations';
import { NgOptimizedImage } from '@angular/common';
import { PromoCodesSliderComponent } from '@components/promo-codes-slider/promo-codes-slider.component';
import { AwardItem } from '@interfaces/award-item.interface';

@Component({
	selector: 'app-awards-page',
	standalone: true,
	imports: [
		RouterLink,
		RouterOutlet,
		NgOptimizedImage,
		RouterLinkActive,
		PromoCodesSliderComponent,
	],
	templateUrl: './awards-page.component.html',
	styleUrl: './awards-page.component.scss',
	animations: [routerAnimation, slideRouter],
})
export class AwardsPageComponent {
	public awards: AwardItem[];
	constructor(private activatedRoute: ActivatedRoute) {
		this.awards = this.activatedRoute.snapshot.data['awards'];
	}
}

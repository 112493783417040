import { Component, HostListener, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '@components/header/header.component';
import { routerAnimation } from '@src/app/helpers/animations';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, HeaderComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	animations: [routerAnimation],
})
export class AppComponent implements OnInit {
	public maxHeight: number;
	public maxWidth: number;

	constructor() {
		// eruda.default.init();
		this.updateSize();
	}
	@HostListener('window:resize', ['$event'])
	public onResize() {
		this.updateSize();
	}

	ngOnInit(): void {
		let lastTouchEnd = 0;

		document.addEventListener(
			'touchend',
			event => {
				const now = new Date().getTime();
				if (now - lastTouchEnd <= 300) {
					event.preventDefault();
				}
				lastTouchEnd = now;
			},
			false
		);
	}

	private updateSize(): void {
		this.maxHeight = window.innerHeight;
		this.maxWidth = window.innerWidth;
	}

	public prepareRoute(outlet: RouterOutlet) {
		return {
			value:
				outlet &&
				outlet.activatedRouteData &&
				outlet.activatedRouteData['animation'],
		};
	}
}

<div class="onboarding">
	<button class="onboarding__skip" (click)="closeOnboarding()"
		>Пропустить</button
	>
	<swiper-container init="false" appSwiper (slideIndex)="changeIndex($event)">
		@for (slide of slides; track slide.id) {
			<swiper-slide>
				<div class="onboarding__slide">
					<div class="onboarding__image">
						<img
							[ngSrc]="'./assets/images/onboarding/onboard-' + slide.id + '.png'"
							alt="image"
							width="280"
							height="280"
							priority />
					</div>
					<div class="onboarding__title" [innerHTML]="slide.h1"></div>
					<div class="onboarding__text" [innerHTML]="slide.text"></div>
				</div>
			</swiper-slide>
		}
	</swiper-container>
	<div class="onboarding__btn-wrapper">
		<button
			class="btn btn-secondary"
			id="slide-prev"
			[ngClass]="{ hide: slideIndex() === 0 }"
			>Назад</button
		>
		<button class="btn btn-primary onboarding__next" (click)="navigateTo()">
			@if (slideIndex() === 3) {
				Играть!
			} @else {
				Далее
			}
		</button>
	</div>
</div>
<app-smart-modal-container></app-smart-modal-container>

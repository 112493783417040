<div class="game-page">
	<app-game-container #game></app-game-container>
	<app-game-control
		[restLives]="restLives$ | async"
		[disableMovement]="disableMovement$ | async"
		(move)="movePlayer($event)"></app-game-control>
<!--  <video class="game-page__video" #videoPlayer playsInline loop muted autoplay (load)="videoLoaded()">-->
<!--    <source src="assets/game-resources/{{currentLevel$ | async}}_lvl.webm?playsinline=1" type="video/webm">-->
<!--    Your browser does not support the video tag.-->
<!--  </video>-->
  <img class="game-page__video" width="500" height="1000" priority ngSrc="./assets/game-resources/lvl_{{currentLevel$ | async}}.gif" alt="Animated WebP" #videoPlayer />

</div>

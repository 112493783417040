import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import {
	animate,
	AnimationBuilder,
	AnimationPlayer,
	keyframes,
	style,
} from '@angular/animations';

@Component({
	selector: 'app-preloader',
	standalone: true,
	imports: [],
	templateUrl: './preloader.component.html',
	styleUrl: './preloader.component.scss',
})
export class PreloaderComponent implements AfterViewInit {
	@Input() initAnimation: boolean = false;
	@Input() loaderText: string;
	@Input() animationTime: number = 3;
	@Output() animationFinished = new EventEmitter<void>();
	private animationPlayer: AnimationPlayer;
	@ViewChild('progressbar') progressbar: ElementRef;

	constructor(private builder: AnimationBuilder) {}

	ngAfterViewInit() {
		if (this.initAnimation) {
			this.preloaderAnimation();
		}
	}

	public preloaderAnimation() {
		const animationTime = this.animationTime + 's';
		const animation = this.builder.build([
			animate(
				animationTime,
				keyframes([
					style({ width: '10%', offset: 0.05 }),
					style({ width: '20%', offset: 0.4 }),
					style({ width: '60%', offset: 0.5 }),
					style({ width: '70%', offset: 0.66 }),
					style({ width: '90%', offset: 0.73 }),
					style({ width: '100%', offset: 1.0 }),
				])
			),
		]);

		this.animationPlayer = animation.create(this.progressbar.nativeElement);
		this.animationPlayer.play();
		this.animationPlayer.onDone(() => {
			this.animationFinished.emit();
		});
	}
}

import { Component } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';

@Component({
	selector: 'app-modal-attempts',
	templateUrl: './modal-attempts.component.html',
	styleUrl: './modal-attempts.component.scss',
	standalone: true,
})
export class ModalAttemptsComponent extends ModalBaseComponent {}

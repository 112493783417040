import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	EMPTY,
	interval,
	map,
	Observable,
	scan,
	switchMap,
	timer,
} from 'rxjs';
import { RestTime } from '@interfaces/rest-time.interface';

@Injectable({
	providedIn: 'root',
})
export class TimerService {
	private milliseconds$ = new BehaviorSubject<number>(0);
	private isPaused$ = new BehaviorSubject<boolean>(false);
	private reset$ = new BehaviorSubject<void>(null);
	private _timerType: 'timer' | 'countdown' = 'countdown';
	public timer$: Observable<RestTime>;

	constructor() {
		this.timer$ = this.reset$.pipe(
			switchMap(() =>
				this.isPaused$.pipe(
					switchMap(paused => (paused ? EMPTY : this.timerType))
				)
			),
			map(remainingMilliseconds => {
				const totalMilliseconds = remainingMilliseconds;
				const totalSeconds = totalMilliseconds / 1000;
				const minutes = Math.floor(totalSeconds / 60);
				const seconds = Math.floor(totalSeconds % 60);
				const milliseconds = totalMilliseconds % 1000;
				this.milliseconds$.next(totalMilliseconds);
				return {
					minutes,
					seconds,
					milliseconds,
					expiration: remainingMilliseconds,
				};
			})
		);
	}

	set timerType(timerType: 'timer' | 'countdown') {
		this._timerType = timerType;
	}

	get timerType(): Observable<number> {
		switch (this._timerType) {
			case 'countdown':
				return this.countdown();
			case 'timer':
				return this.timer();
		}
	}

	start(milliseconds: number) {
		this.milliseconds$.next(milliseconds);
		this.isPaused$.next(false);
	}

	pause() {
		this.isPaused$.next(true);
	}

	resume() {
		this.isPaused$.next(false);
	}

	reset() {
		this.reset$.next();
	}

	private countdown(): Observable<number> {
		return timer(0, 100).pipe(
			scan(acc => acc - 100, this.milliseconds$.value),
			map(value => Math.max(value, 0)) // Ensure value doesn't go below 0
		);
	}

	private timer(): Observable<number> {
		return interval(100).pipe(scan(acc => acc + 100, this.milliseconds$.value));
	}
}

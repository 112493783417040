import {
	animate,
	group,
	query,
	style,
	transition,
	trigger,
} from '@angular/animations';

export const routerAnimation = trigger('routeAnimations', [
	transition('* <=> *', [
		style({ position: 'relative' }),
		query(
			':enter, :leave',
			[
				style({
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					width: '100%',
					height: '100%',
				}),
			],
			{ optional: true }
		),
		query(':enter', [style({ opacity: 0 })], { optional: true }),
		query(
			':leave',
			[style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
			{ optional: true }
		),
		query(':enter', [animate('0.3s', style({ opacity: 1 }))], {
			optional: true,
		}),
	]),
]);

export const slideRouter = trigger('slide', [
	transition('* => coupons', [
		style({ position: 'relative' }),
		query(
			':enter, :leave',
			[
				style({
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					width: '100%',
					height: '100%',
				}),
			],
			{ optional: true }
		),
		query(':enter', [style({ left: '-100%' })], {
			optional: true,
		}),
		query(':leave', [style({ left: '0%' })], { optional: true }),
		group([
			query(':leave', [animate('0.5s ease-out', style({ left: '100%' }))], {
				optional: true,
			}),
			query(':enter', [animate('0.5s ease-out', style({ left: '0%' }))], {
				optional: true,
			}),
		]),
	]),
	transition('* => promo-codes', [
		style({ position: 'relative' }),
		query(
			':enter, :leave',
			[
				style({
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					width: '100%',
					height: '100%',
				}),
			],
			{ optional: true }
		),
		query(':enter', [style({ left: '100%' })], {
			optional: true,
		}),
		query(':leave', [style({ left: '0%' })], { optional: true }),
		group([
			query(':leave', [animate('0.5s ease-out', style({ left: '-100%' }))], {
				optional: true,
			}),
			query(':enter', [animate('0.5s ease-out', style({ left: '0%' }))], {
				optional: true,
			}),
		]),
	]),
]);

export enum StatisticAction {
	AMOUNT_PAYED_GAMES_ATTEMPTS = 'количество запущенных платных попыток Игры ',
	AMOUNT_FREE_GAMES_ATTEMPTS = 'количество запущенных бесплатных попыток Игры ',
	AMOUNT_PAYED_GAMES_NOT_COMPLETED = 'количество незавершенных платных попыток Игры ',
	AMOUNT_FREE_GAMES_NOT_COMPLETED = 'количество незавершенных бесплатных попыток Игры ',
	AMOUNT_EXITS_BY_CROSS_PAYED = 'количество выходов по крестику из платных попыток Игры ',
	AMOUNT_EXITS_BY_CROSS_FREE = 'количество выходов по крестику из бесплатных попыток Игры ',
	AMOUNT_COMPLETED_GAMES_PAYED = 'количество завершенных платных попыток Игры ',
	AMOUNT_COMPLETED_GAMES_FREE = 'количество завершенных бесплатных попыток Игры ',
	LAST_COMPLETED_LEVEL_NUMBER = 'Номер последнего пройденного уровня Игры ',
	COMPLETE_START_ONBOARDING = 'Флаг о прочтении стартового онбординга',
	COLLECTION_STATISTIC = 'кол-во кликов по узнать больше в коллекции',
	COPY_PROMO_CODE_DEFAULT = 'кол-во скопированных ашан промокодов',
	COPY_PROMO_CODE_PREMIER = 'кол-во скопированных premier промокодов',
	CLICK_ON_ACTIVATE = 'клик по кнопке активировать',
}

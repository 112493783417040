import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { LogoWrapperComponent } from '@components/logo-wrapper/logo-wrapper.component';
import { PlayerDirections } from '@type/player-directions.type';
import { AsyncPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-game-control',
	standalone: true,
	imports: [LogoWrapperComponent, NgOptimizedImage, AsyncPipe, NgClass],
	templateUrl: './game-control.component.html',
	styleUrl: './game-control.component.scss',
})
export class GameControlComponent {
	@Input() set restLives(value: number) {
		this.lives$.next(value);
		if (value > 0 && value < 3) {
			console.log(value);
			this.showBanner$.next(true);
			setTimeout(() => this.showBanner$.next(false), 2000);
		}
	}
	@Input() disableMovement: PlayerDirections | null;
	@Output() move = new EventEmitter<PlayerDirections>();
	@ViewChild('banner', { static: true }) banner: ElementRef;

	public lives$ = new BehaviorSubject(3);
	public showBanner$ = new BehaviorSubject<boolean>(false);

	closeBanner() {
		this.showBanner$.next(false);
	}

	moveLeft() {
		this.move.emit('left');
	}
	moveRight() {
		this.move.emit('right');
	}
}

import { Component, inject } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { ApiService } from '@services/api.service';
import { firstValueFrom } from 'rxjs';
import { StateService } from '@services/state.service';
import { StatisticAction } from '@enums/statistic-action.enum';

@Component({
	selector: 'app-modal-exit-onboarding',
	templateUrl: './modal-exit-onboarding.component.html',
	styleUrl: './modal-exit-onboarding.component.scss',
	standalone: true,
})
export class ModalExitOnboardingComponent extends ModalBaseComponent {
	private api: ApiService = inject(ApiService);
	private state: StateService = inject(StateService);

	public async exitOnboarding() {
		if (this.state.onboarding) {
			this.state.onboarding = false;
			await firstValueFrom(this.api.postOnboarding());
			firstValueFrom(
				this.api.sendStatistic(StatisticAction.COMPLETE_START_ONBOARDING, '1')
			);
			await this.closeAndNavigate('/core');
			return;
		}
		await this.closeAndNavigate('/core');
	}
}

import { Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
	selector: 'app-cracker-animation',
	standalone: true,
	imports: [LottieComponent],
	templateUrl: './cracker-animation.component.html',
	styleUrl: './cracker-animation.component.scss',
})
export class CrackerAnimationComponent {
	public crackerConfig: AnimationOptions = {
		path: '/assets/images/cracker.json',
		autoplay: true,
		loop: true,
		renderer: 'svg',
		rendererSettings: {
			viewBoxSize: '100%',
		},
	};
}

import { Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CollectionSlide } from '@interfaces/collection-slide.interface';
import { SwiperDirective } from '@src/app/directives/swiper.directive';
import { SwiperOptions } from 'swiper/types';
import { NgOptimizedImage } from '@angular/common';
import { StateService } from '@services/state.service';

@Component({
	selector: 'app-collection-page',
	standalone: true,
	imports: [RouterOutlet, RouterLink, SwiperDirective, NgOptimizedImage],
	templateUrl: './collection-page.component.html',
	styleUrl: './collection-page.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CollectionPageComponent {
	private state: StateService = inject(StateService);
	public level: number = this.state.user.nextLevel;
	public slides: CollectionSlide[] = [
		{ id: 1, level: 1, image: 'assets/images/collection/lvl-1-1.png' },
		{ id: 2, level: 1, image: 'assets/images/collection/lvl-1-2.png' },
		{ id: 3, level: 1, image: 'assets/images/collection/lvl-1-3.png' },
		{ id: 4, level: 2, image: 'assets/images/collection/lvl-2-1.png' },
		{ id: 5, level: 2, image: 'assets/images/collection/lvl-2-2.png' },
		{ id: 6, level: 2, image: 'assets/images/collection/lvl-2-3.png' },
		{ id: 7, level: 2, image: 'assets/images/collection/lvl-2-4.png' },
		{ id: 8, level: 3, image: 'assets/images/collection/lvl-3-1.png' },
		{ id: 9, level: 3, image: 'assets/images/collection/lvl-3-2.png' },
		{ id: 10, level: 3, image: 'assets/images/collection/lvl-3-3.png' },
		{ id: 11, level: 3, image: 'assets/images/collection/lvl-3-4.png' },
		{ id: 12, level: 4, image: 'assets/images/collection/lvl-4-1.png' },
		{ id: 13, level: 5, image: 'assets/images/collection/lvl-5-1.png' },
		{ id: 14, level: 6, image: 'assets/images/collection/lvl-6-1.png' },
		{ id: 15, level: 7, image: 'assets/images/collection/lvl-7-1.png' },
		{ id: 16, level: 8, image: 'assets/images/collection/lvl-8-1.png' },
		{ id: 17, level: 8, image: 'assets/images/collection/lvl-8-2.png' },
		{ id: 18, level: 9, image: 'assets/images/collection/lvl-9-1.png' },
		{ id: 19, level: 10, image: 'assets/images/collection/lvl-10-1.png' },
		{ id: 20, level: 11, image: 'assets/images/collection/lvl-11-1.png' },
		{ id: 21, level: 13, image: 'assets/images/collection/lvl-13-1.png' },
		{ id: 22, level: 14, image: 'assets/images/collection/lvl-14-1.png' },
		{ id: 23, level: 16, image: 'assets/images/collection/lvl-16-1.png' },
		{ id: 24, level: 17, image: 'assets/images/collection/lvl-17-1.png' },
		{ id: 25, level: 17, image: 'assets/images/collection/lvl-17-2.png' },
		{ id: 26, level: 18, image: 'assets/images/collection/lvl-18-1.png' },
		{ id: 27, level: 19, image: 'assets/images/collection/lvl-19-1.png' },
		{ id: 28, level: 19, image: 'assets/images/collection/lvl-19-2.png' },
		{ id: 29, level: 20, image: 'assets/images/collection/lvl-20-1.png' },
		{ id: 30, level: 20, image: 'assets/images/collection/lvl-20-2.png' },
	];

	public sliderConfig: SwiperOptions = {
		slidesPerView: 3,
		spaceBetween: 12,
		grid: {
			rows: 3,
		},
	};

	public get openedCollection(): number {
		return this.slides.reduce((a, b) => {
			if (b.level < this.level) {
				return a + 1;
			}
			return a;
		}, 0);
	}
}

import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from '@services/api.service';
import { OnboardingSlide } from '@interfaces/onboarding-slide.interface';

export const onboardingResolver: ResolveFn<OnboardingSlide[]> = (
	route,
	state,
	api = inject(ApiService)
) => {
	return api.getOnboarding();
};

<div class="game-container" #container>
<!--  <div class="container" #itemsContainer>-->
<!--    <div class="box box1" >-->
<!--&lt;!&ndash;      <img class="label" src="./assets/game-resources/percentage-label.svg">&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="box box2">-->
<!--&lt;!&ndash;      <img class="label" src="./assets/game-resources/percentage-label.svg">&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="box box3">-->
<!--&lt;!&ndash;      <img class="label" src="./assets/game-resources/percentage-label.svg">&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="box box4">-->
<!--&lt;!&ndash;      <img class="label" src="./assets/game-resources/percentage-label.svg">&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="box box5">-->
<!--&lt;!&ndash;      <img class="label" src="./assets/game-resources/percentage-label.svg">&ndash;&gt;-->

<!--    </div>-->
<!--  </div>-->

</div>

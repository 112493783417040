import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from '@services/api.service';
import { StartGame } from '@interfaces/start-game.interface';

export const startGameResolver: ResolveFn<StartGame> = (
	route,
	state,
	api = inject(ApiService)
) => {
	return api.startGame();
};

import {
	Component,
	ElementRef,
	OnInit,
	signal,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { PreloaderComponent } from '@components/preloader/preloader.component';
import {
	animate,
	AnimationBuilder,
	AnimationFactory,
	AnimationPlayer,
	style,
} from '@angular/animations';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { NgOptimizedImage, NgStyle } from '@angular/common';
import { firstValueFrom, map, switchMap } from 'rxjs';
import { environment } from '@env/environment';
import { CrackerAnimationComponent } from '@components/cracker-animation/cracker-animation.component';

@Component({
	selector: 'app-start-page',
	standalone: true,
	imports: [
		PreloaderComponent,
		LottieComponent,
		NgStyle,
		CrackerAnimationComponent,
		NgOptimizedImage,
	],
	templateUrl: './start-page.component.html',
	styleUrl: './start-page.component.scss',
})
export class StartPageComponent implements OnInit {
	public showCracker$ = signal<boolean>(false);
	@ViewChild('logo', { static: true }) logoRef: ElementRef;
	@ViewChild('logoTitle', { static: true }) logoTitleRef: ElementRef;
	@ViewChild('logoText', { static: true }) logoTextRef: ElementRef;
	@ViewChild('age', { static: true }) ageRef: ElementRef;
	@ViewChild('preloaderContainer') preloaderContainerRef: ElementRef;
	@ViewChild('preloader') preloaderRef: PreloaderComponent;

	public crackerConfig: AnimationOptions = {
		path: '/assets/images/cracker.json',
		autoplay: true,
		loop: true,
		renderer: 'svg',
		rendererSettings: {
			viewBoxSize: '100%',
		},
	};
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private data: ApiService,
		private ab: AnimationBuilder
	) {
		firstValueFrom(
			this.activatedRoute.queryParams.pipe(
				map(params => {
					const sessionId = environment.production
						? params['sessionId']
						: 'anime-ga-suki-desu-ka';
					return sessionId;
				}),
				switchMap(sessionId => this.data.authUser(sessionId))
			)
		);
	}

	public ngOnInit() {
		this.createLogoAnimation().onDone(() => this.createAgeAnimation());
	}

	private createLogoAnimation(): AnimationPlayer {
		const logoAnimation: AnimationFactory = this.ab.build([
			animate('500ms 1000ms ease-in', style({ opacity: '0' })),
		]);
		const titleAnimation: AnimationFactory = this.ab.build([
			animate('500ms 1000ms ease-in', style({ opacity: '0' })),
		]);
		const player = logoAnimation.create(this.logoRef.nativeElement);
		titleAnimation.create(this.logoTitleRef.nativeElement).play();
		titleAnimation.create(this.logoTextRef.nativeElement).play();
		player.play();
		return player;
	}

	private createAgeAnimation(): void {
		const ageAnimation: AnimationFactory = this.ab.build([
			animate('500ms 100ms ease-in', style({ opacity: '1' })),
		]);
		const player = ageAnimation.create(this.ageRef.nativeElement);

		player.play();
		const preloaderAnimation = this.ab.build([
			style({ transform: 'translateY(1000%)' }),
			animate('500ms 100ms ease-in', style({ transform: 'translateY(-100%)' })),
		]);

		this.showCracker$.set(true);
		const preloaderPlayer = preloaderAnimation.create(
			this.preloaderContainerRef.nativeElement
		);
		preloaderPlayer.play();
		preloaderPlayer.onDone(() => this.preloaderRef.preloaderAnimation());
	}

	public navigateToOnboarding(): void {
		this.router.navigate(['onboarding']);
	}
}

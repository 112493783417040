import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { StateService } from '@services/state.service';

export const authGuard: CanActivateFn = (
	route,
	state,
	data = inject(StateService),
	router = inject(Router)
) => {
	if (data.user) {
		return true;
	} else {
		router.navigate(['/']);
		return false;
	}
};

import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { GameService } from '@services/game.service';
import { ChipType } from '@type/chip-types.type';
import { TimeWithZeroPipe } from '@src/app/helpers/time-with-zero.pipe';

@Component({
	selector: 'app-header',
	standalone: true,
	imports: [AsyncPipe, TimeWithZeroPipe],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
})
export class HeaderComponent {
	@Input() showHeader: boolean;
	@Input() hideChip: boolean;
	@Input() chipType: ChipType = 'lives';
	@Input() lives: number = 0;
	private modal: ModalService = inject(ModalService);
	private game: GameService = inject(GameService);
	private router: Router = inject(Router);

	public timer$ = this.game.timer$;

	public openModal(): void {
		this.modal.openModal(ModalType.LIVES_DESCRIPTION);
	}

	public close(): void {
		const isModalOpen = this.modal.state.isOpen;
		const modalType = this.modal.state.type;

		if (modalType === ModalType.PAUSE) {
			this.modal.closeModal();
			return this.game.resume();
		}

		if (modalType === ModalType.LEVEL_FAILED) {
			this.modal.closeModal();
			this.router.navigate(['../core']);
			return;
		}

		if (
			modalType === ModalType.LEVEL_FINISHED &&
			this.game?.stopGameResponse?.is20LevelComplete
		) {
			return this.modal.openModal(ModalType.ALL_LEVELS);
		}

		if (modalType === ModalType.LEVEL_FINISHED) {
			this.modal.closeModal();
			this.router.navigate(['../core']);
			return;
		}

		if (isModalOpen) {
			this.modal.closeModal();
			return;
		}

		let route: string = this.router.url.replace('/core/', '');

		switch (route) {
			case 'awards':
			case 'collection':
			case 'faq':
				this.router.navigate(['../core']);
				break;
			case 'game':
				this.modal.openModal(ModalType.PAUSE);
				this.game.pause();
				break;
			default:
				this.modal.openModal(ModalType.CLOSE_GAME);
		}
	}
}

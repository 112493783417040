import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { StateService } from '@services/state.service';

export const onboardingGuard: CanActivateFn = (
	route,
	state,
	data = inject(StateService),
	router = inject(Router)
) => {
	if (!data.onboarding) {
		return router.navigate(['/core']);
	}
	return true;
};

<div class="modal-exit">
  <div class="modal-exit__inner">
    <img class="modal-exit__bird" src="./assets/images/bird.svg"/>
    <div class="modal-exit__title">Выйти из игры?</div>
    <div class="modal-exit__btn-wrapper">
      <a href="auchan://" class="modal-exit__btn btn btn-secondary btn-yes">Да</a>
      <button class="modal-exit__btn btn btn-primary btn-no" (click)="closeModal()">Нет, вернуться</button>
    </div>
  </div>
</div>

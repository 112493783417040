import { Component } from '@angular/core';
import {
	ActivatedRoute,
	NavigationEnd,
	Router,
	RouterOutlet,
} from '@angular/router';
import { SmartModalContainerComponent } from '@modal/components/smart-modal-container/smart-modal-container.component';
import { HeaderComponent } from '@components/header/header.component';
import { routerAnimation } from '@src/app/helpers/animations';
import {
	combineLatestAll,
	combineLatestWith,
	filter,
	map,
	Observable,
	startWith,
	switchMap,
} from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { RouteData } from '@interfaces/route-data.interface';
import { ChipType } from '@type/chip-types.type';
import { StateService } from '@services/state.service';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';

@Component({
	selector: 'app-core-page',
	standalone: true,
	imports: [
		RouterOutlet,
		SmartModalContainerComponent,
		HeaderComponent,
		AsyncPipe,
	],
	templateUrl: './core-page.component.html',
	styleUrl: './core-page.component.scss',
	animations: [routerAnimation],
})
export class CorePageComponent {
	showHeader$: Observable<boolean>;
	hideChip$: Observable<boolean>;
	chipType$: Observable<ChipType>;
	lives$: Observable<number>;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateService,
		private modal: ModalService
	) {
		const filteredRoutes$ = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			startWith(null),
			switchMap(() => {
				const childRoute = this.route.firstChild;
				return childRoute.data as Observable<RouteData>;
			})
		);
		this.hideChip$ = modal.modalState$.pipe(
			map(
				modalState =>
					modalState.isOpen && modalState.type !== ModalType.LIVES_DESCRIPTION
			)
		);
		this.showHeader$ = filteredRoutes$.pipe(
			map(data => data.showHeader),
			combineLatestWith(modal.modalState$),
			map(
				([showHeader, modalState]) =>
					showHeader &&
					!(modalState.isOpen && modalState.type === ModalType.LOADER)
			)
		);
		this.chipType$ = filteredRoutes$.pipe(
			map(data => (data?.chipType ? data.chipType : 'lives'))
		);
		this.lives$ = this.state.user$.pipe(map(user => user.attempts));
	}

	public prepareRoute(outlet: RouterOutlet) {
		return {
			value:
				outlet &&
				outlet.activatedRouteData &&
				outlet.activatedRouteData['animation'],
		};
	}
}

import { Component, Input } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { FinishLevelData } from '@interfaces/finish-level-data.interface';
import { StopGameResponse } from '@interfaces/stop-game-response.interface';
import { ExpressPointsPipe } from '@src/app/helpers/express-points.pipe';

@Component({
	selector: 'app-modal-level-finish',
	templateUrl: './modal-all-levels-complete.component.html',
	styleUrl: './modal-all-levels-complete.component.scss',
	standalone: true,
	imports: [ExpressPointsPipe],
})
export class ModalAllLevelsCompleteComponent extends ModalBaseComponent {
	@Input() data: StopGameResponse;

	closeFinishModal() {
		if (this.data.is20LevelComplete) {
		} else {
			this.closeAndNavigate('../core');
		}
	}
}

<div class="logo" #logo>
	<h2 class="logo__title" #logoTitle>День Рождения</h2>
	<div class="logo__wrapper">
		<img
			class="logo__image"
			src="./assets/images/logo-auchan.svg"
			alt="логотип" />
	</div>
  <h2 class="logo__title" #logoText>успевай на цен падение!
  </h2>
</div>
@if (showCracker$()) {
	<app-cracker-animation></app-cracker-animation>
}

<div class="gift-box" #age>
	<img
		ngSrc="./assets/images/gift-box.png"
		height="385"
		width="282" />
</div>

<div class="preloader" #preloaderContainer>
	<app-preloader
		#preloader
		(animationFinished)="navigateToOnboarding()"></app-preloader>
</div>

<div class="modal-component exit-onboarding">
  <img src="../../assets/images/bird.svg" alt="" class="exit-onboarding__bird">
	<h2 class="exit-onboarding__title"
		>Пропустить <br />
    обучение и перейти <br />
		в меню?</h2
	>
	<div class="exit-onboarding__btns-wrapper">
		<button class="btn btn-secondary btn-yes" (click)="exitOnboarding()"
			>Выйти</button
		>
		<button class="btn btn-primary btn-no" (click)="closeModal()">Вернуться</button>
	</div>
</div>

import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
	selector: 'app-game-container',
	standalone: true,
	imports: [],
	templateUrl: './game-container.component.html',
	styleUrl: './game-container.component.scss',
})
export class GameContainerComponent {
	@ViewChild('container', { static: true }) container: ElementRef;
	@ViewChild('itemsContainer', { static: true }) itemsContainer: ElementRef;
}

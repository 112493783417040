import { Component, inject } from '@angular/core';
import { FaqItem } from '@interfaces/faq-item.interface';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { StateService } from '@services/state.service';

@Component({
	selector: 'app-faq-page',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './faq-page.component.html',
	styleUrl: './faq-page.component.scss',
})
export class FaqPageComponent {
	private actRouter: ActivatedRoute = inject(ActivatedRoute);
	private router: Router = inject(Router);
	private state: StateService = inject(StateService);
	public items: FaqItem[] = this.actRouter.snapshot.data['items'];
	public userId: number = this.state.user.id;

	public toOnboarding(): void {
		this.state.onboarding = true;
		this.router.navigate(['../../onboarding']);
	}
}

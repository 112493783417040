<div class="game-control">
	<div class="game-control__inner">
		<button
			class="game-control__btn"
			(touchend)="moveLeft()"
			[disabled]="disableMovement === 'left'">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill=""
				xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.01183 2.06532C9.74601 1.33977 10.9273 1.33984 11.6613 2.06548C12.4075 2.80308 12.4076 4.00808 11.6615 4.7458L6.2194 10.1269L21.5946 10.1269C22.6297 10.1269 23.4688 10.966 23.4688 12.0011C23.4688 13.0362 22.6297 13.8753 21.5946 13.8753L6.2194 13.8753L11.6613 19.2546C12.4075 19.9922 12.4075 21.1971 11.6613 21.9347C10.9273 22.6603 9.74606 22.6603 9.01201 21.9347L1.64924 14.6567C0.959603 13.977 0.531409 13.0389 0.531409 11.9991C0.531409 10.9613 0.959603 10.0211 1.64924 9.34145L9.01183 2.06532Z"
					fill="" />
			</svg>
		</button>
		<div class="game-control__lifes">
			@if ((lives$ | async) >= 1) {
				<div class="game-control__life">
					<img src="./assets/images/life.png" />
				</div>
			} @else {
				<div class="game-control__life">
					<img src="./assets/images/life-empty.png" />
				</div>
			}

			@if ((lives$ | async) >= 2) {
				<div class="game-control__life">
					<img src="./assets/images/life.png" />
				</div>
			} @else {
				<div class="game-control__life">
					<img src="./assets/images/life-empty.png" />
				</div>
			}

			@if ((lives$ | async) >= 3) {
				<div class="game-control__life">
					<img src="./assets/images/life.png" />
				</div>
			} @else {
				<div class="game-control__life">
					<img src="./assets/images/life-empty.png" />
				</div>
			}
			<!--      <div class="game-control__life">-->
			<!--        <img src="./assets/images/life.png"/>-->
			<!--      </div>-->
			<!--      <div class="game-control__life">-->
			<!--        <img src="./assets/images/life-empty.png"/>-->
			<!--      </div>-->
		</div>
		<button
			class="game-control__btn _right"
			(touchend)="moveRight()"
			[disabled]="disableMovement === 'right'">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill=""
				xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.01183 2.06532C9.74601 1.33977 10.9273 1.33984 11.6613 2.06548C12.4075 2.80308 12.4076 4.00808 11.6615 4.7458L6.2194 10.1269L21.5946 10.1269C22.6297 10.1269 23.4688 10.966 23.4688 12.0011C23.4688 13.0362 22.6297 13.8753 21.5946 13.8753L6.2194 13.8753L11.6613 19.2546C12.4075 19.9922 12.4075 21.1971 11.6613 21.9347C10.9273 22.6603 9.74606 22.6603 9.01201 21.9347L1.64924 14.6567C0.959603 13.977 0.531409 13.0389 0.531409 11.9991C0.531409 10.9613 0.959603 10.0211 1.64924 9.34145L9.01183 2.06532Z"
					fill="" />
			</svg>
		</button>
	</div>
	<app-logo-wrapper></app-logo-wrapper>
</div>

<div class="banner" [ngClass]="{show: showBanner$ | async}"
	><img
		ngSrc="./assets/images/banner-percent.svg"
		alt="баннер"
		class="banner__img"
		height="37"
		width="30" />
	<p class="banner__text">Упс, ты пропустил товар :(</p>
	<button class="banner__btn" (click)="closeBanner()">
		<svg
			width="10"
			height="10"
			viewBox="0 0 15 15"
			fill="#fff"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.66355 1.0901C2.95291 0.379463 1.80074 0.379461 1.09011 1.0901C0.379471 1.80073 0.379469 2.9529 1.0901 3.66354L4.92656 7.50001L1.0901 11.3365C0.379469 12.0471 0.379471 13.1993 1.09011 13.9099C1.80074 14.6206 2.95291 14.6205 3.66355 13.9099L7.5 10.0734L11.3365 13.9099C12.0471 14.6205 13.1993 14.6206 13.9099 13.9099C14.6205 13.1993 14.6205 12.0471 13.9099 11.3365L10.0734 7.50001L13.9099 3.66354C14.6205 2.9529 14.6205 1.80073 13.9099 1.0901C13.1993 0.379461 12.0471 0.379463 11.3365 1.0901L7.5 4.92656L3.66355 1.0901Z"
				fill="" />
		</svg>
	</button>
</div>

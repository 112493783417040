import { Component, inject } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { StateService } from '@services/state.service';

@Component({
	selector: 'app-modal-lives-description',
	templateUrl: './modal-lives-description.component.html',
	styleUrl: './modal-lives-description.component.scss',
	standalone: true,
})
export class ModalLivesDescriptionComponent extends ModalBaseComponent {
	public user = inject(StateService).user;
}

import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { PreloaderComponent } from '@components/preloader/preloader.component';
import { ModalBaseComponent } from '@modal/components/modal-base/modal-base.component';
import { GameService } from '@services/game.service';

@Component({
	selector: 'app-modal-loader',
	standalone: true,
	imports: [PreloaderComponent],
	templateUrl: './modal-loader.component.html',
	styleUrl: './modal-loader.component.scss',
})
export class ModalLoaderComponent extends ModalBaseComponent {
	private game = inject(GameService);

	startGame() {
		console.log('start game');
		this.closeModal();
		this.game.startGame();
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'expressPoints',
	standalone: true,
})
export class ExpressPointsPipe implements PipeTransform {
	transform(value: number): string {
		if (value === 1) {
			return '1 экспресс-балл';
		}
		if (value > 1 && value < 5) {
			return `${value} экспресс-балла`;
		}
		return `${value} экспресс-баллов`;
	}
}

<div class="error-page">
<!--  <div class="logo">-->
<!--    <div class="logo__wrapper">-->
<!--      <img-->
<!--        class="logo__decoration"-->
<!--        src="./assets/images/logo-decoration.png"-->
<!--        alt="декорация" />-->
<!--      <img-->
<!--        class="logo__image"-->
<!--        src="./assets/images/logo-auchan.svg"-->
<!--        alt="логотип" />-->
<!--    </div>-->
<!--  </div>-->
  <a href="auchan://" class="error-page__close _error close-btn">
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66355 1.0901C2.95291 0.379463 1.80074 0.379461 1.09011 1.0901C0.379471 1.80073 0.379469 2.9529 1.0901 3.66354L4.92656 7.50001L1.0901 11.3365C0.379469 12.0471 0.379471 13.1993 1.09011 13.9099C1.80074 14.6206 2.95291 14.6205 3.66355 13.9099L7.5 10.0734L11.3365 13.9099C12.0471 14.6205 13.1993 14.6206 13.9099 13.9099C14.6205 13.1993 14.6205 12.0471 13.9099 11.3365L10.0734 7.50001L13.9099 3.66354C14.6205 2.9529 14.6205 1.80073 13.9099 1.0901C13.1993 0.379461 12.0471 0.379463 11.3365 1.0901L7.5 4.92656L3.66355 1.0901Z" fill=""/>
    </svg>
  </a>
  <div class="error-page__title">Что-то пошло <br> не так</div>
  <div class="error-page__text">Мы скоро починим</div>
</div>

import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from '@services/api.service';
import { OnboardingSlide } from '@interfaces/onboarding-slide.interface';
import { User } from '@interfaces/user.interface';

export const userResolver: ResolveFn<User> = (
	route,
	state,
	api = inject(ApiService)
) => {
	return api.getUser();
};

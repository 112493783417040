import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ApiService } from './api.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
	const dataService = inject(ApiService);
	if (req.url.includes('/api/') && !req.url.includes('auth')) {
		const token = dataService.token;
		const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
		const clonedRequest = req.clone({ headers });
		return next(clonedRequest);
	}

	return next(req);
};

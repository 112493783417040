import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
	selector: 'app-logo-wrapper',
	standalone: true,
	imports: [NgOptimizedImage],
	templateUrl: './logo-wrapper.component.html',
	styleUrl: './logo-wrapper.component.scss',
})
export class LogoWrapperComponent {}

<div class="modal-attempts">
  <button class="modal-attempts__close close-btn" (click)="closeModal()">
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66355 1.0901C2.95291 0.379463 1.80074 0.379461 1.09011 1.0901C0.379471 1.80073 0.379469 2.9529 1.0901 3.66354L4.92656 7.50001L1.0901 11.3365C0.379469 12.0471 0.379471 13.1993 1.09011 13.9099C1.80074 14.6206 2.95291 14.6205 3.66355 13.9099L7.5 10.0734L11.3365 13.9099C12.0471 14.6205 13.1993 14.6206 13.9099 13.9099C14.6205 13.1993 14.6205 12.0471 13.9099 11.3365L10.0734 7.50001L13.9099 3.66354C14.6205 2.9529 14.6205 1.80073 13.9099 1.0901C13.1993 0.379461 12.0471 0.379463 11.3365 1.0901L7.5 4.92656L3.66355 1.0901Z" fill=""/>
    </svg>
  </button>
  <div class="modal-attempts__inner">
    <img class="modal-attempts__bird" src="./assets/images/bird.svg"/>
    <div class="modal-attempts__title">Нет попыток</div>
    <div class="modal-attempts__text">
      Совершай покупки и получай по 4 призовые игры за каждую 1000 рублей в чеке. Или приходи завтра за бесплатной игрой.
    </div>
    <a href="auchan://category?code=den_rozhdeniya_ashan_2022" class="btn btn-primary">Перейти в АШАН</a>
  </div>
</div>


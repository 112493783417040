import { Component, inject } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { GameService } from '@services/game.service';

@Component({
	selector: 'app-modal-pause',
	templateUrl: './modal-pause.component.html',
	styleUrl: './modal-pause.component.scss',
	standalone: true,
})
export class ModalPauseComponent extends ModalBaseComponent {
	private game = inject(GameService);

	resume(): void {
		this.closeModal();
		this.game.resume();
	}

	closeGame() {
		this.game.close();
		this.closeAndNavigate('/core');
	}
}

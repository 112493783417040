import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	inject,
	signal,
	ViewChild,
} from '@angular/core';
import { OnboardingSlide } from '@interfaces/onboarding-slide.interface';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { SwiperDirective } from '@src/app/directives/swiper.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { SmartModalContainerComponent } from '@modal/components/smart-modal-container/smart-modal-container.component';
import { ApiService } from '@services/api.service';
import { firstValueFrom } from 'rxjs';
import { StateService } from '@services/state.service';
import { StatisticAction } from '@enums/statistic-action.enum';

@Component({
	selector: 'app-onboarding-page',
	standalone: true,
	imports: [
		NgOptimizedImage,
		SwiperDirective,
		NgClass,
		SmartModalContainerComponent,
	],
	templateUrl: './onboarding-page.component.html',
	styleUrl: './onboarding-page.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OnboardingPageComponent {
	private modal: ModalService = inject(ModalService);
	private router: Router = inject(Router);
	private api: ApiService = inject(ApiService);
	private state: StateService = inject(StateService);
	private actRouter: ActivatedRoute = inject(ActivatedRoute);
	public slides: OnboardingSlide[] = this.actRouter.snapshot.data['slides'];
	public slideIndex = signal<number>(0);
	@ViewChild(SwiperDirective) slider: SwiperDirective;

	public closeOnboarding() {
		this.modal.openModal(ModalType.EXIT_ONBOARDING);
	}

	public async navigateTo() {
		if (this.slideIndex() !== this.slides.length - 1) {
			this.slider.swiper.slideNext();
			return;
		}
		this.state.onboarding = false;
		firstValueFrom(this.api.postOnboarding()).then(() => {
			this.router.navigate(['../core']);
		});
		firstValueFrom(
			this.api.sendStatistic(StatisticAction.COMPLETE_START_ONBOARDING, '1')
		);
		return;
	}

	public changeIndex(index: number) {
		this.slideIndex.set(index);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'timeWithZero',
	standalone: true,
})
export class TimeWithZeroPipe implements PipeTransform {
	transform(value: number): string {
		return value < 10 ? `0${value}` : `${value}`;
	}
}

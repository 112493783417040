import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toDateString',
	standalone: true,
})
export class ToDateStringPipe implements PipeTransform {
	transform(value: string): string {
		const targetDate = new Date(value);
		const now = new Date();
		const difference = targetDate.getTime() - now.getTime();

		if (difference <= 0) {
			return 'Ещё 0 дн. и 0 ч.';
		}

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);

		return `Еще ${days} дн. и ${hours} ч.`;
	}
}

import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FaqItem } from '@interfaces/faq-item.interface';

export const faqResolver: ResolveFn<FaqItem[]> = (
	route,
	state,
	api = inject(ApiService)
) => {
	return api.getFaq();
};

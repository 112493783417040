import { Injectable } from '@angular/core';
import { User } from '@interfaces/user.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class StateService {
	private _user = new BehaviorSubject<User>(null);

	constructor() {}

	public get user$(): Observable<User> {
		return this._user.asObservable();
	}

	public get user(): User {
		return this._user.value;
	}

	public set user(value: User) {
		this._user.next(value);
	}

	public set onboarding(value: boolean) {
		this._user.next({ ...this.user, isOnboarding: value });
	}

	public get onboarding(): boolean {
		return this._user.value.isOnboarding;
	}
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService {
	constructor(private router: Router) {}

	private errorRedirect() {
		console.error('redirect tto error');
		this.router.navigate(['/error']);
	}

	public handleHttpError() {
		return <T>(source: Observable<T>) =>
			source.pipe(
				catchError(error => {
					this.errorRedirect();
					throw error;
				})
			);
	}
}

import { Component } from '@angular/core';
import { CrackerAnimationComponent } from '@components/cracker-animation/cracker-animation.component';

@Component({
	selector: 'app-pre-release-page',
	standalone: true,
	imports: [CrackerAnimationComponent],
	templateUrl: './pre-release-page.component.html',
	styleUrl: './pre-release-page.component.scss',
})
export class PreReleasePageComponent {
	releaseText: string;

	constructor() {
		this.releaseText = this.getHolidayCountdown();
	}

	private getHolidayCountdown(): string {
		const targetDate = new Date('2024-09-10T00:00:00+03:00'); // Дата начала праздника (Москва, 00:00)
		const currentDateUTC = new Date();

		// Рассчитываем текущее московское время (UTC+3)
		const moscowOffset = 3 * 60; // Смещение для Москвы
		const currentMoscowTime = new Date(
			currentDateUTC.getTime() +
				(currentDateUTC.getTimezoneOffset() + moscowOffset) * 60000
		);

		// Устанавливаем дату и время для 11:00 утра по Москве
		const elevenAMDate = new Date('2024-09-10T11:00:00+03:00');

		// Вычисляем разницу в миллисекундах между текущей датой и датой праздника
		const timeDifference = targetDate.getTime() - currentMoscowTime.getTime();
		const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

		// Если текущее время после 11:00 10 сентября, убираем заглушку
		if (currentMoscowTime >= elevenAMDate) {
			return '';
		}
		console.log(
			'date text',
			currentMoscowTime.getDate(),
			targetDate,
			currentMoscowTime.getTime(),
			elevenAMDate.getTime()
		);
		// Если время после 00:00, но до 11:00, выводим "Праздник совсем скоро!"
		if (
			currentMoscowTime.getDate() >= targetDate.getDate() &&
			currentMoscowTime.getTime() < elevenAMDate.getTime()
		) {
			return 'Праздник начнется совсем скоро!';
		}

		// Если до праздника более 3 дней
		if (daysRemaining > 3) {
			return 'Праздник начнется совсем скоро!';
		}

		// Выбираем нужный текст в зависимости от оставшихся дней до праздника
		if (daysRemaining > 1) {
			return `Праздник начнется через ${daysRemaining} дня!`;
		}

		// Когда остается 1 день до праздника
		return 'Праздник начнется через 1 день!';
	}
}

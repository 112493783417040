
<div class="logo" #logo>
  <h2 class="logo__title" #logoTitle>День Рождения</h2>
  <div class="logo__wrapper">
    <img
      class="logo__image"
      src="./assets/images/logo-auchan.svg"
      alt="логотип" />
  </div>
  <h2 class="logo__title" #logoText>успевай на цен падение!</h2>
</div>

<div class="preloader" >
  <app-preloader
    [initAnimation]="true"
    [animationTime]="5"
    (animationFinished)="startGame()"></app-preloader>
</div>

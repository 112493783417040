import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from '@env/environment';
import {
	catchError,
	EMPTY,
	map,
	Observable,
	shareReplay,
	switchMap,
	tap,
} from 'rxjs';
import { Auth } from '@interfaces/auth.interface';
import { ServerResponse } from '@interfaces/server-response.interface';
import { User } from '@interfaces/user.interface';
import { StateService } from '@services/state.service';
import { FaqItem } from '@interfaces/faq-item.interface';
import { OnboardingSlide } from '@interfaces/onboarding-slide.interface';
import { StartGame } from '@interfaces/start-game.interface';
import { StopGame } from '@interfaces/stop-game.interface';
import { StopGameResponse } from '@interfaces/stop-game-response.interface';
import { AwardItem } from '@interfaces/award-item.interface';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private state = inject(StateService);
	private _token: string;
	private api = environment.apiEndpoint;

	constructor(
		private http: HttpClient,
		private error: ErrorHandlerService
	) {}

	get token() {
		return this._token;
	}

	set token(value) {
		this._token = value;
	}

	public getFaq(): Observable<FaqItem[]> {
		return this.http
			.get<ServerResponse<FaqItem[]>>(this.api + '/game/faq')
			.pipe(
				map(response => response.data),
				this.error.handleHttpError()
			);
	}

	public sendStatistic(action: string, objectRaw: unknown): Observable<string> {
		const object = `${objectRaw}`;
		return this.http
			.post<ServerResponse<string>>(this.api + '/game/stat', {
				action,
				object,
			})
			.pipe(
				map(res => res.data),
				catchError(err => {
					console.log('statistic error', err);
					return EMPTY;
				})
			);
	}

	public getAwards(): Observable<AwardItem[]> {
		return this.http
			.get<ServerResponse<AwardItem[]>>(this.api + '/game/awards')
			.pipe(
				map(response => response.data),
				this.error.handleHttpError()
			);
	}

	public getOnboarding(): Observable<OnboardingSlide[]> {
		return this.http
			.get<ServerResponse<OnboardingSlide[]>>(this.api + '/game/onboarding')
			.pipe(
				map(response => response.data),
				this.error.handleHttpError()
			);
	}

	public getUser(): Observable<User> {
		return this.http.get<ServerResponse<User>>(this.api + '/game/user').pipe(
			map(response => response.data),
			tap(user => (this.state.user = user)),
			this.error.handleHttpError()
		);
	}

	public postOnboarding(): Observable<unknown> {
		return this.http
			.post<ServerResponse<StartGame>>(this.api + '/game/onboarding', {})
			.pipe(
				map(response => response.data),
				this.error.handleHttpError()
			);
	}

	public startGame(): Observable<StartGame> {
		return this.http
			.post<ServerResponse<StartGame>>(this.api + '/game/startGame', {})
			.pipe(
				map(response => response.data),
				shareReplay(),
				this.error.handleHttpError()
			);
	}

	public stopGame(data: StopGame): Observable<StopGameResponse> {
		return this.http
			.post<ServerResponse<StopGameResponse>>(this.api + '/game/stopGame', data)
			.pipe(
				map(response => response.data),
				shareReplay(),
				this.error.handleHttpError()
			);
	}

	public authUser(sessionId: string): Observable<any> {
		return this.http
			.post<ServerResponse<Auth>>(this.api + '/auth/login', { sessionId })
			.pipe(
				map(response => {
					if (response.data.token) {
						this.token = response.data.token;
					} else {
						throw new Error('No token found');
					}
				}),
				switchMap(() => this.getUser()),
				this.error.handleHttpError()
			);
	}
}

import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { SwiperOptions, Swiper } from 'swiper/types';
import { register } from 'swiper/element/bundle';
register();

interface SwiperElement extends HTMLElement {
	initialize?: () => void;
}

@Directive({
	selector: '[appSwiper]',
	standalone: true,
})
export class SwiperDirective implements AfterViewInit {
	@Input() set extendConfig(config: SwiperOptions) {
		this.config = { ...this.config, ...config };
	}
	@Output() slideIndex = new EventEmitter<number>();

	private swiperElement: SwiperElement;
	private config: SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 25,
		on: {
			activeIndexChange: slide => {
				this.slideIndex.next(slide.activeIndex);
			},
			init: swiper => {
				this.swiper = swiper;
			},
		},
		noSwiping: true,
		allowTouchMove: false,
		navigation: {
			prevEl: '#slide-prev',
			nextEl: '#slide-next',
		},
	};
	public swiper: Swiper;

	constructor(private element: ElementRef<HTMLElement>) {}

	ngAfterViewInit() {
		this.swiperElement = this.element.nativeElement;
		Object.assign(this.element.nativeElement, this.config);
		this.swiperElement.initialize();
	}
}
